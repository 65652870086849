import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface LayoutState {
    minHeight: number;
    footerVisible: boolean;
    mapLoaded: boolean;
    enterFormVisible: boolean;
    enterFormMessage: string | null;
    registrationFormVisible: boolean;
    isSoonVisible: boolean;
    restorePassFormVisible: boolean;
    changePassFormVisible: boolean;
    enterByEmailVisible: boolean;
    emailEnterForm: boolean;
    finishRegistrationFormVisible: boolean;
    dontShowFinishRegForm: boolean;
    enterCodeFormVisible: boolean;
    technicalPause?: boolean;
    callbackFormVisible?: boolean;
    supportFormVisible: boolean;
}

const initialState = {
    technicalPause: process.env.NEXT_PUBLIC_TECHNICAL_PAUSE === "true",
    minHeight: 0,
    footerVisible: true,
    enterFormVisible: false,
    enterFormMessage: null,
    registrationFormVisible: false,
    isSoonVisible: false,
    changePassFormVisible: true,
    enterByEmailVisible: false,
    emailEnterForm: false,
    finishRegistrationFormVisible: false,
    enterCodeFormVisible: false,
    callbackFormVisible: false,
    supportFormVisible: false,
    dontShowFinishRegForm: false
} as LayoutState;

export const modalsNames = [
    "enterFormVisible",
    "registrationFormVisible",
    "restorePassFormVisible",
    "changePassFormVisible",
    "enterByEmailVisible",
    "emailEnterForm",
    "finishRegistrationFormVisible",
    "enterCodeFormVisible",
    "callbackFormVisible",
    "supportFormVisible"
];

const hideModalsMethod = (state: LayoutState, except?: string) => {
    for (let modalName of modalsNames) {
        // @ts-ignore
        if (modalName !== except && state[modalName]) {
            // @ts-ignore
            state[modalName] = false;
        }
    }
};

const layoutSlice = createSlice({
    name: "layout",
    initialState,
    reducers: {
        setMinHeight(state, action: PayloadAction<number>) {
            state.minHeight = action.payload;
        },
        disableFooter(state) {
            state.footerVisible = false;
        },
        enableFooter(state) {
            state.footerVisible = true;
        },
        mapLoaded(state) {
            state.mapLoaded = true;
        },
        showEnterForm(state, action: PayloadAction<string | undefined>) {
            hideModalsMethod(state);
            state.enterFormVisible = true;
            state.enterFormMessage = action.payload ?? null;
        },
        hideEnterForm(state) {
            state.enterFormVisible = false;
            state.enterFormMessage = null;
        },
        showRegistrationForm(state) {
            hideModalsMethod(state);

            state.registrationFormVisible = true;
        },
        showEnterByEmail(state) {
            hideModalsMethod(state);
            state.enterByEmailVisible = true;
        },
        showCallbackForm(state) {
            hideModalsMethod(state);
            state.callbackFormVisible = true;
        },
        showSupportForm(state) {
            hideModalsMethod(state);
            state.supportFormVisible = true;
        },
        hideRegistrationForm(state) {
            state.registrationFormVisible = false;
        },
        setIsSoonVisible(state, action: PayloadAction<boolean>) {
            state.isSoonVisible = action.payload;
        },
        showRestorePassForm(state) {
            hideModalsMethod(state);
            state.restorePassFormVisible = true;
        },
        hideRestorePassForm(state) {
            state.restorePassFormVisible = false;
        },
        showChangePassFormVisible(state) {
            state.changePassFormVisible = true;
        },
        showEnterByEmailVisible(state) {
            hideModalsMethod(state);
            state.emailEnterForm = true;
        },
        hideChangePassFormVisible(state) {
            state.changePassFormVisible = false;
        },
        hideModals(state, action: PayloadAction<string | undefined>) {
            hideModalsMethod(state, action.payload);
        },
        dontShowFinishRegForm(state) {
            state.dontShowFinishRegForm = true;
        },
        showFinishRegistrationForm(state) {
            if (state.dontShowFinishRegForm === true) {
                return;
            }
            hideModalsMethod(state);
            state.finishRegistrationFormVisible = true;
        },
        showEnterCodeForm(state) {
            hideModalsMethod(state);
            state.enterCodeFormVisible = true;
        }
    }
});

export const {
    setMinHeight,
    disableFooter,
    enableFooter,
    mapLoaded,
    showEnterForm,
    hideEnterForm,
    showRegistrationForm,
    hideRegistrationForm,
    setIsSoonVisible,
    showRestorePassForm,
    hideRestorePassForm,
    hideChangePassFormVisible,
    hideModals,
    showEnterByEmail,
    showEnterByEmailVisible,
    showFinishRegistrationForm,
    dontShowFinishRegForm,
    showEnterCodeForm,
    showCallbackForm,
    showSupportForm
} = layoutSlice.actions;
export default layoutSlice;