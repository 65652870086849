import React, { useEffect, useState } from "react";
import Image from "next/image";
import { CircleStencil, Cropper, CropperRef } from "react-advanced-cropper";
import { CloseIcon } from "@shared/ui/Icon/ui/Common/CloseIcon";
import { AvatarProfileProps } from "./types";
import "react-advanced-cropper/dist/style.css";
import classes from "./AvatarProfile.module.scss";

export const AvatarProfile = ({ initialSrc, onChange, onDelete }: AvatarProfileProps) => {
    const [previewUrl, setPreviewUrl] = useState<string>(initialSrc);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [croppedData, setCroppedData] = useState<CropperRef | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setPreviewUrl(URL.createObjectURL(file));
            setOpenModal(true);
        }
    };

    const handleCrop = () => {
        if (!croppedData) return;
        const canvas = croppedData.getCanvas();
        if (canvas) {
            canvas.toBlob((blob) => {
                if (blob) {
                    const croppedFile = new File([blob], "avatar.jpg", { type: "image/jpeg" });
                    setPreviewUrl(URL.createObjectURL(croppedFile));
                    onChange(croppedFile);
                }
            }, "image/jpeg");
        }
        setOpenModal(false);
    };

    const handleDelete = () => {
        setPreviewUrl("/img/placeholder-avatar.webp");
        onDelete();
    };

    const handleCancel = () => {
        setPreviewUrl(initialSrc);
        setOpenModal(false);
    };

    useEffect(() => {
        setPreviewUrl(initialSrc || "/img/placeholder-avatar.webp");
    }, [initialSrc]);

    return (
        <div className={classes.avatar}>
            <div className={classes.avatar__preview}>
                <Image
                    className={classes.avatar__logo}
                    src={previewUrl}
                    alt="Превью логотипа"
                    width={60}
                    height={60}
                />
                {previewUrl !== "/img/placeholder-avatar.webp" && (
                    <button className={classes.avatar__delete} onClick={handleDelete}>
                        <CloseIcon className={classes["avatar__delete--icon"]} />
                    </button>
                )}
            </div>
            <label htmlFor="file-upload" className={classes.avatar__label}>
                Загрузить логотип
            </label>
            <input
                id="file-upload"
                type="file"
                accept="image/*"
                className={classes.avatar__input}
                onChange={handleFileChange}
            />
            {openModal && (
                <>
                    <div className={classes.avatar__overlay} onClick={handleCancel} />
                    <div className={classes.avatar__modal}>
                        <p className={classes.avatar__edit}>Редактировать логотип</p>
                        <Cropper
                            src={previewUrl}
                            className={classes.avatar__cropper}
                            stencilComponent={CircleStencil}
                            onChange={(data) => setCroppedData(data)}
                        />
                        <div className={classes.avatar__actions}>
                            <button onClick={handleCrop} className={classes.avatar__save}>
                                Сохранить
                            </button>
                            <button className={classes.avatar__cancel} onClick={handleCancel}>
                                Отмена
                            </button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};