import { RouterConfig } from "@shared/lib/routerConfig";

export const PRIVATE_ROUTES = [
    RouterConfig.PROFILE,
    RouterConfig.PROFILE_COMPANY,
    RouterConfig.MY_ADS,
    RouterConfig.FAVORITES,
    RouterConfig.HISTORY,
    RouterConfig.CREATE_OFFER,
    RouterConfig.COMPARE
];